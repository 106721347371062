import { api } from '../../lib/api';
import { createApiAction } from '../../redux/createApiAction';

export const loadProperties = createApiAction(
	'tickets/load_property',
	({ accountId, customerId }: { accountId: number; customerId: number }) => {
		return api.get(`/api/v1/properties/account/${accountId}/customer/${customerId}`);
	}
);

export const loadTicketTypes = createApiAction(
	'tickets/load_ticket_type',
	({ casaviTenantId, casaviContactId }: { casaviTenantId: number; casaviContactId: number }) => {
		return api.get(
			`/api/v1/tickets/ticket_types/tenant/${casaviTenantId}/contact/${casaviContactId}`
		);
	}
);
