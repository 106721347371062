import React, { useEffect, useState } from 'react';
import { Alert, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Dropdown } from '../../../components/Dropdown';
import ActionBar from '../../../mui-custom/ActionBar/ActionBar';
import { useMobile } from '../../../hooks/useBreakpoints';
import { useDispatch } from 'react-redux';
import { loadCustomers } from '../../customers/actions';
import { initialValue, AddTicketBusiness } from './AddTicket.business';

export const AddTicketPage = () => {
	const { t } = useTranslation();
	const isMobile = useMobile();
	const dispatch = useDispatch();

	const { accountId, propertyManagers, properties, ticketTypes } =
		AddTicketBusiness.useTicketData();

	useEffect(() => {
		if (!propertyManagers) dispatch(loadCustomers());
	}, []);

	const propertyManagerControl = useState(initialValue);
	const [propertyManager] = propertyManagerControl;

	const propertyControl = useState(initialValue);
	const [property, setProperty] = propertyControl;

	const ticketTypeControl = useState(initialValue);
	const [ticketType, setTicketType] = ticketTypeControl;

	useEffect(() => {
		AddTicketBusiness.loadPropertiesAndTicketTypes(
			propertyManager,
			propertyManagers,
			accountId,
			dispatch,
			setProperty,
			setTicketType
		);
	}, [propertyManager?.name]);

	return (
		<Stack sx={isMobile ? { justifyContent: 'space-between', height: '100%', m: 1 } : { m: 1 }}>
			<Stack spacing={4} sx={{ mb: 4 }}>
				<Typography variant="h6">{t('tickets:creation_section_title')}</Typography>
				<Dropdown
					label={t('tickets:property_manager')}
					values={propertyManagers}
					control={propertyManagerControl}
					filterFields={['casaviTenantId', 'casaviContactId']}
				/>
				{!!propertyManager.name && !properties.length && (
					<Alert severity="error">{t('tickets:no_property_error')}</Alert>
				)}
				<Dropdown
					label={t('tickets:property')}
					values={properties}
					control={propertyControl}
					disabled={!propertyManager.name}
				/>
				{!!propertyManager.name && !ticketTypes.length && (
					<Alert severity="error">{t('tickets:no_ticket_type_error')}</Alert>
				)}
				<Dropdown
					label={t('tickets:ticket_type')}
					values={ticketTypes}
					control={ticketTypeControl}
					disabled={!propertyManager.name}
				/>
			</Stack>
			<ActionBar
				primary={{
					children: t('general:cta_next'),
					href: '/app/tickets/add/custom-fields',
					disabled: !propertyManager.name || !property.name || !ticketType.name
				}}
			/>
		</Stack>
	);
};
