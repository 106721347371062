import { Alert, Stack, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const AddCustomFieldsPage = () => {
	const { t } = useTranslation();
	return (
		<Stack sx={{ m: 1 }}>
			<Typography variant="h6">{t('2. custom fields page')}</Typography>
			<br />
			<Alert severity="info">{t('tickets:success_message')}</Alert>
		</Stack>
	);
};
