import React from 'react';
import { Route } from 'react-router-dom';
import { AddTicketPage } from './single/AddTicketPage';
import { AddCustomFieldsPage } from './single/AddCustomFieldsPage';
import { ListPage } from './list/ListPage';
import { Stack } from '@mui/material';
import { useMobile } from '../../hooks/useBreakpoints';
import { NAVBAR_HEIGHT } from '../navbar/App';
import { AccountSpecificFeature, useAccountSpecificFeature } from '../acl/features';

export const TicketsApp = () => {
	const isMobile = useMobile();
	const hasTicketsCreation = useAccountSpecificFeature(AccountSpecificFeature.TicketCreation);
	if (!hasTicketsCreation) return null;

	return (
		<Stack
			sx={{
				maxWidth: 800 /* 800px for desktop mode from RI-1499 design */,
				width: isMobile ? '100vw' : '100%'
			}}
		>
			{/* TabNav? */}
			<Route exact path="/app/tickets" component={ListPage} />
			<Stack sx={{ mx: 2, mt: 4, height: isMobile ? `calc(100vh - ${NAVBAR_HEIGHT})` : '100%' }}>
				<Route exact={isMobile} path="/app/tickets/add" component={AddTicketPage} />
				<Route
					exact={isMobile}
					path="/app/tickets/add/custom-fields"
					component={AddCustomFieldsPage}
				/>
				{/* SubPageNav with stepper instead cta button in actionBar */}
			</Stack>
		</Stack>
	);
};
