import { useSelector } from 'react-redux';
import { State } from '../../reducers';
import { loadProperties, loadTicketTypes } from '../actions';
import { Dispatch } from 'redux';
import { DropdownItemValue } from '../../../components/Dropdown';
import { ResponseCustomer } from '../../../../server/customers/browser.types';

export const initialValue = { name: '', id: 0 } as DropdownItemValue;

export class AddTicketBusiness {
	static useTicketData() {
		const accountId = useSelector((state: State) => state.auth?.user?.accountId);
		const propertyManagers = useSelector((state: State) => state.customers?.list?.data);
		const { properties, ticketTypes } = useSelector((state: State) => state.tickets);

		return { accountId, propertyManagers, properties, ticketTypes };
	}

	static loadPropertiesAndTicketTypes(
		propertyManager: DropdownItemValue,
		propertyManagers: ResponseCustomer[],
		accountId: number,
		dispatch: Dispatch<any>,
		setProperty: (value: DropdownItemValue) => void,
		setTicketType: (value: DropdownItemValue) => void
	) {
		if (!propertyManager.name) {
			setProperty(initialValue);
			setTicketType(initialValue);
			return;
		}

		const { id, casaviTenantId, casaviContactId } =
			propertyManagers.find((pM) => pM.name === propertyManager.name) || {};

		if (!id) setProperty(initialValue);
		else dispatch(loadProperties({ accountId, customerId: id }));

		if (!casaviTenantId || !casaviContactId) setTicketType(initialValue);
		else dispatch(loadTicketTypes({ casaviTenantId, casaviContactId }));
	}
}
