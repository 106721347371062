import { combineReducers } from 'redux';
import { createReducer } from '../../redux/createReducer';
import { loadProperties, loadTicketTypes } from './actions';
import { Property } from '../../../server/db/models/Property';

export interface UITicketTypes { id: number; name: string }
export interface TicketsState {
	properties: Property[];
	ticketTypes: UITicketTypes[];
	[x: string]: unknown /* reserved for custom fields and temp saved form data */;
}

export const ticketsReducers = combineReducers<TicketsState>({
	properties: createReducer<Property[]>([]).handleAction(
		loadProperties.success,
		(_state, action) => action.payload
	),
	ticketTypes: createReducer<UITicketTypes[]>([]).handleAction(
		loadTicketTypes.success,
		(_state, action) => action.payload
	)
});
